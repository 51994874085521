.about_us
{
    position: relative;
    align-items: center;
    padding:0px;
    background-color: #FFFFFF;
    text-align: center;
    justify-content: center;
    display: flex;
}

.about_us p
{
    font-size: 40px;
    color:rgb(163, 199, 231,100%);
    font-style: normal;
}
