
/*-------------- service --------------*/
.service    
{
    position: relative;
    background-color:#FFFFFF;
    border-top:3px solid #8AA2C0;
    display: flex;
    height: 800px;

}
.service h1
{
    position: absolute;
    align-items: center;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5E5E5E;
    padding-left: 42%;
    padding-top:10%;
    font-size: 48px;
}
.left_p
{
    position: absolute;
    color: rgba(0, 0, 0, 0.63);
    text-align: center;
    top:40%;
    left:5%;
    font-size: 32px;
    z-index: 2;
}
.right_p
{
    position: absolute;
    color: rgba(0, 0, 0, 0.63);
    text-align: center;
    top:40%;
    right:5%;
    font-size: 32px;
    z-index: 2;
}
span
{
    text-transform: uppercase;
}
.line3
{
    position: absolute;
    height: 300px;
    width: 0px;
    border: 2.5px solid #A3C7E7;
    filter: blur(1px);
    top:35%;
    left:49%;
}
.line1
{
    position: absolute;
    width: 300px;
    background-color:  #DAE8F5;
    filter: blur(5px);
    right:10%;
}
.line2
{
    position: absolute;
    width: 300px;
    background-color:  #DAE8F5;
    filter: blur(5px);
    left:8%;
}