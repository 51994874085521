

.fares
{
    position: relative;
    border-top:3px solid #8AA2C0;
    background: linear-gradient(180deg, #DAE8F5 0%,#FFFFFF 59.9%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.fares h1
{
    position: absolute;
    top:10%;
    left:43%;
    color:#3F454C;
    font-size: 44px;
}
.right_fare
{
    position: absolute;
    width: 200px;
    height: 300px;
    background-color: #FFFFFF;
    border-radius: 12px;
    top:35%;
    right: 15%;
}
.left_fare
{
    position: absolute;
    width: 200px;
    height: 300px;
    background-color: #FFFFFF;
    border-radius: 12px;
    top:35%;
    left: 15%;

}
.midle_fare
{
    position: absolute;
    width: 250px;
    height: 400px;
    background-color:#FFFFFF;
    top:30%;
    left:39%;
    border-radius: 12px;

}
.fares h2
{
    position: absolute;
    text-align: center;
    top:10%;
    color:rgb(63,69,76,100%);
}
.clients_count
{
    position: absolute;
    top:40%;
    left:15%;
    font-size: 20px;
    padding-left: 10px;
    color:rgb(63,69,76,68%);

}
.clients_count::before
{
    content: '';
    width: 10px;
    height: 10px;
    background-color: #3F454C;
    display: block;
    border-radius: 50% ;
    position: absolute;
    left:-10%;
    top:20%;
}
.price
{
    position: absolute;
    bottom:10%;
    left:25%;
    font-size: 28px;
    color:#7C8185;
}
#left_arrow
{
    position: absolute;
    width: 20px;
    top:50%;
    left:10%;
}
#right_arrow
{
    position: absolute;
    width: 20px;
    top:50%;
    right:10%;
}
