*
{
    padding:0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    font-family: 'Helvetica Neue';
    font-style: italic;
}
body
{
    background-color: #DAE8F5;
}
.wrapper
{
    position: relative;
    max-width: 1200px;
    margin:auto;
}
div
{
    height: 800px;
    width: 100%;
}
@media(max-width:1200px)
{
    .wrapper
    {
        max-width: 970px;
    }
    .header
    {
        height: 450px;
    }
}
@media(max-width:992px)
{
    .wrapper{
        max-width: 750px;
    }
    .header 
    {
        height:350px ;
    }
}
@media(max-width:767px)
{
    .wrapper{
        max-width: none;
    }
    .header 
    {
        height:350px ;
    }
}