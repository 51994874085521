

/*-------------- calculator --------------*/
.calculator
{
    position: relative;
    background-color: #FFFFFF;
    border-top:3px solid #8AA2C0;
}
.calculator_form
{
    position: relative;
    width: 1000px;
    height: 500px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 50px;
    border: 0.5px solid #D1E0EE;
    box-shadow: 0px 5px 5px 3px #D9E6F1;
    box-sizing: border-box;
}
label{
    font-size: 20px;
}
.input_label1
{
    position: absolute;
    left:10%;
    top:10%;
}
.input_label2
{
    position: absolute;
    left:10%;
    top:25%;
}
.input_label3
{
    position: absolute;
    left:10%;
    top:40%;
}
.input1
{
    position: absolute;
    width: 350px;
    top:10%;
    left:55%;
    box-sizing: border-box;
    border: 2px solid #D1E0EE;
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
}
#select_fares
{
    position: absolute;
    width: 350px;
    top:25%;
    left:55%;
    box-sizing: border-box;
    border: 2px solid #D1E0EE;
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    color:gray
}
#input2
{
    position: absolute;
    width: 350px;
    top:40%;
    left:55%;
    box-sizing: border-box;
    border: 2px solid #D1E0EE;
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
}
.price_form
{
    position: relative;
    width: 90%;
    height: 20%;
    background-color: #FAFAFA;
    top:70%;
    left:5%;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    align-items: center;
    display: flex;
}
#result_label1
{
    position: absolute;
    left:5%;
}
#result_label2
{
    position: absolute;
    left:20%;
}
.calculator_form button
{
    position: absolute;
    top:55%;
    left:45%;
    width: 80px;
    height: 30px;
}
.btn
{
    width: 50px;
    height: 50px;
}
