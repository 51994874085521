
/*-------------- partners --------------*/
.partners
{
    position: relative;
    background-color: #FFFFFF;
    border-top:3px solid #8AA2C0;
    display: flex;
    justify-content: center;
}
h1
{
    color:rgba(63, 69, 76, 1);
    position: absolute;
    top:5%;
    font-size: 48px;
}
.partners p
{
    position: absolute;
    color:rgba(63, 69, 76, 1);
    top:20%;
    font-size: 40px;
    word-spacing: 5px;
}
.audi
{
    position: absolute;
    top:50%;
    left:15%;
    width: 200px;
}
.mango
{
    position: absolute;
    top:80%;
    left:15%;
    width: 200px;
}
.skillbox
{
    position: absolute;
    top:60%;
    left:45%;
    width: 120px;
}
.mtc
{
    position: absolute;
    top:80%;
    right:15%;
    width: 180px;
}
.skyeng
{
    position: absolute;
    top:56%;
    right:15%;
    width: 200px;
}