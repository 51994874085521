
/*-------------- header --------------*/
.header
{
    position: relative;
    height:561px;
    width: 100%;
    color: #FFFFFF;
}
img
{
    max-width: 100%;
    position: absolute;
    display: block;
}
ul
{
    padding-top: 20px;
    font-size: 18px;
    position: absolute;
}
li
{
    float: left;
    padding-left: 70px;
}
.header p
{
    position: absolute;
    text-align: center;
    font-size: 36px;
    margin:20% 0 0 10% ;
}

